import useSWR from "swr";
import { mutateData } from "./api";
import { Endpoints } from "./endpoints";

export interface SettingsData {
  map_visible: number;
}

export const useSettings = () => {
  const { data, mutate } = useSWR(Endpoints.settings);

  const UpdateSettings = async (data: SettingsData) => {
    let bodyFormData = new FormData();
    bodyFormData.append("settings", JSON.stringify(data));
    await mutateData("post", Endpoints.settings, bodyFormData).then((res) => {
      mutate();
    });
  };

  return {
    settings: data,
    UpdateSettings,
  };
};
