import { Box, Container, Typography, Link } from "@mui/material";
import { gameMenu } from "../../constants/Menu";
import styles from "./main.module.scss";

const Main = () => {
  return (
    <Container maxWidth="xl" className={styles.container}>
      <Box sx={{ color: "#fff" }}>
        <Box className={styles.textBox}>
          <Typography
            variant="h3"
            sx={{
              padding: "16px 0",
            }}
            className={styles.header}
          >
            Игра на машинах в Риге
          </Typography>
        </Box>

        <Box sx={{ margin: "32px 0" }}>
          {gameMenu.map((page, i) => (
            <Box sx={{ textAlign: "left", margin: "16px 0" }}>
              <Link
                key={page.title + i}
                href={page.link}
                underline="hover"
                sx={{
                  color: "#fff",
                }}
              >
                {page.title}
              </Link>
            </Box>
          ))}
        </Box>
      </Box>
    </Container>
  );
};

export default Main;
