import { Box, Button, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../api/useAuth";

interface AuthProps {}

export default function Auth({}: AuthProps) {
  const { isAdmin, Login } = useAuth();
  const navigate = useNavigate();

  const [password, setPassword] = useState<string>("");

  const handleSubmit = () => {
    Login({ password });
    setPassword("");
  };

  useEffect(() => {
    if (isAdmin) navigate("/settings");
  }, [isAdmin]);

  return (
    <Box sx={{ maxWidth: 400, margin: "auto", p: 6 }}>
      <Typography variant="h4">Admin login</Typography>
      <TextField
        required
        type="password"
        label="Password"
        onKeyDown={(e) => e.key === "Enter" && handleSubmit()}
        fullWidth
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        sx={{
          mb: 2,
          mt: 2,
        }}
      />
      <Button variant="contained" sx={{ width: 180 }} onClick={handleSubmit}>
        Login
      </Button>
    </Box>
  );
}
