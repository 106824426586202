import axios from "axios";

export const instance = axios.create({
  baseURL: "https://perehvat.lv/perehvat-vue",
});

export const mutateData = (
  type: "patch" | "delete" | "post" | "put" | "get",
  url: string,
  data?: any,
  headers?: {
    "Content-Type"?: string;
    "Access-Control-Allow-Origin"?: string;
  },
  responseType?: any,
  onUploadProgress?: (progressEvent: any) => void
) => {
  return instance({
    method: type,
    url: url,
    data,
    headers,
    responseType,
    onUploadProgress,
    withCredentials: true,
  });
};

const fetcher = (url: string) =>
  instance.get(url, { withCredentials: true }).then((res: any) => res.data);

export const SWROptions = {
  fetcher,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};
