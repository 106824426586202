import {
  AppBar,
  Box,
  Container,
  Drawer,
  IconButton,
  Link,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import React from "react";
import { menu } from "../../constants/Menu";
import styles from "./header.module.scss";

const Header = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const HomeButtonLabel = "PEREHVAT";

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: "transparent" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            className={styles.homeButton}
            sx={{
              display: { xs: "none", md: "flex" },
            }}
          >
            {HomeButtonLabel}
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {menu.map((page, i) => (
              <Link
                key={page.title + i}
                href={page.link}
                underline="hover"
                sx={{
                  color: "#fff",
                  padding: "8px 24px",
                }}
              >
                {page.title}
              </Link>
            ))}
          </Box>

          {/* Mobile */}
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="left"
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              PaperProps={{ square: true, sx: { padding: "16px" } }}
            >
              {menu.map((page, i) => (
                <Box key={page.title + i} sx={{ padding: "16px 16px" }}>
                  <Link
                    href={page.link}
                    underline="none"
                    sx={{
                      color: "#333",
                    }}
                  >
                    {page.title}
                  </Link>
                </Box>
              ))}
            </Drawer>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            className={styles.homeButton}
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
            }}
          >
            {HomeButtonLabel}
          </Typography>
          {/* end mobile */}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
