import { createBrowserRouter } from "react-router-dom";
import Settings from "../components/AdminPanel/Settings";
import Auth from "../components/Auth/Auth";
import Root from "../components/Root";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
  },
  {
    path: "/auth",
    element: <Auth />,
  },
  {
    path: "/settings",
    element: <Settings />,
  },
]);
