export const menu: MenuPages[] = [
  {
    title: "Группа на Facebook",
    link: "https://www.facebook.com/groups/342494072797393/",
  },
  { title: "Instagram", link: "https://www.instagram.com/perehvat.lv/" },
  {
    title: "Чат в Messenger",
    link: "https://l.facebook.com/l.php?u=https%3A%2F%2Fm.me%2Fjoin%2FAbbNqNgvB7fCAhJu%3Ffbclid%3DIwAR1rMuG6Z6h_6orj32DvZ-mnnQWJ2fkZMp3hJ5clzp23zW-6nnnAi7LnxpI&h=AT2yeYNVUppcrpbFDo9QW0GkKf8yR_SzMYrjM62f2D-ZVs-DdJNQ0CE-f64IFnzotXhvyRcGs7u5UFYEYWBgVRwaLdVxzYek4jn4sDyor-zIdTvSrRZs0ryWezKtjOMSfhWhOQ",
  },
];

export const gameMenu: MenuPages[] = [
  { title: "Карта", link: "https://perehvat.lv/igra/" },
  {
    title: "Рация Zello",
    link: "https://zello.com/channels/k/fZ6yC?fbclid=IwAR1exPqU0Y6Qbmj-BIuJJrX0WCGcRTWfpQzyF5qITJTb55PygxTwqksczP4",
  },
  {
    title: "Правила игры",
    link: "https://docs.google.com/document/d/1TwE_rES9RACVkfKXFdrRhZUXYWuB31w-/edit?usp=sharing&ouid=116124384871857011020&rtpof=true&sd=true",
  },
];

interface MenuPages {
  title: string;
  link: string;
}
