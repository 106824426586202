import "./App.css";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes/Routes";
import { SWRConfig } from "swr";
import { SWROptions } from "./api/api";

function App() {
  return (
    <SWRConfig value={SWROptions}>
      <div className="App">
        <RouterProvider router={router} />
      </div>
    </SWRConfig>
  );
}

export default App;
