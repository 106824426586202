import { Box, Button, Switch, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../api/useAuth";
import { SettingsData, useSettings } from "../../api/useSettings";

interface SettingsProps {}

export default function Settings({}: SettingsProps) {
  const { settings, UpdateSettings } = useSettings();
  const { isAdmin } = useAuth();
  const navigate = useNavigate();

  const [formSettings, setFormSettings] = useState<SettingsData>({
    map_visible: +(!!settings?.map_visible ?? false),
  });
  const handleSubmit = () => {
    UpdateSettings(formSettings);
  };

  useEffect(() => {
    if (settings) setFormSettings(settings);
  }, [settings]);

  if (!isAdmin) {
    navigate("/auth");
    return null;
  }

  return (
    <Box sx={{ p: 5, maxWidth: 600, margin: "auto" }}>
      <Typography variant="h4" sx={{ mb: 4 }}>
        Settings
      </Typography>
      <Box
        sx={{
          margin: "auto",
          p: 4,
          boxShadow: "0 0 20px #ccc",
          borderRadius: 2,
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography>Map is visible</Typography>
          <Switch
            checked={!!formSettings.map_visible}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setFormSettings({ map_visible: +event.target.checked })
            }
          />
        </Box>
      </Box>
      <Button
        variant="contained"
        sx={{ width: 180, mt: 4 }}
        onClick={handleSubmit}
      >
        Save
      </Button>
    </Box>
  );
}
