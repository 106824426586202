import Header from "../layouts/Header/Header";
import Main from "./Main/Main";
import Video from "./Video/Video";

interface RootProps {}

export default function Root({}: RootProps) {
  return (
    <div>
      <Video />
      <Header />
      <Main />
    </div>
  );
}
