import React from "react";
import styles from "./video.module.scss";

const Video = () => {
  return (
    <video disablePictureInPicture autoPlay muted loop playsInline className={styles.bgVideo}>
      <source src="./../../assets/perehvat.mp4" type="video/mp4" />
    </video>
  );
};

export default Video;
