import sha1 from "sha1";
import useSWR from "swr";
import { mutateData } from "./api";
import { Endpoints } from "./endpoints";

interface LoginData {
  password: string;
}

export const useAuth = () => {
  const { data, mutate } = useSWR(Endpoints.auth);
  const isAdmin = data?.type === "admin";

  const Login = async (data: LoginData) => {
    let bodyFormData = new FormData();
    bodyFormData.append("password", sha1(data.password));
    await mutateData("post", Endpoints.auth, bodyFormData).then(() => {
      mutate();
    });
  };

  return {
    data,
    isAdmin,
    Login,
  };
};
